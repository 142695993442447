import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'

import { catchHttpError, getData } from './../helpers/api'

import {
  IHappeningResponse,
  IHappeningResponseConfiguration,
  IHappeningResponseSpace,
  IHappeningsResponse,
  IHappeningsResponseItemMetadata
} from './api.types'
import {
  IHappeningData,
  IHappeningDescription,
  IHappeningSlotsConfiguration,
  IHappeningSpace
} from './happenings.types'

class HappeningsApi {
  private static nextDayStartHour: number = 7

  private static calculateStartDate(_startDate: string): Date {
    const startDate = moment(_startDate)
    const actualDate = moment()

    return moment().diff(startDate) < 0
      ? startDate.toDate()
      : actualDate.hour() < HappeningsApi.nextDayStartHour
      ? actualDate.subtract(1, 'days').toDate()
      : actualDate.toDate()
  }

  public static normalizeHappening(
    {
      activityTimeInSlots,
      allowNextSlotWhenOverbooked,
      calculatePricePerPerson,
      endDate,
      id,
      imageUrl,
      maxNumberOfPeople,
      metadata,
      multipleSlotReservation,
      partner,
      spaces,
      startDate
    }: IHappeningResponse,
    slug: string
  ): IHappeningData {
    return {
      activityTimeInSlots,
      calculatePricePerPerson,
      descriptions: metadata.map(HappeningsApi.normalizeMetadata),
      endDate: endDate ? moment(endDate).toDate() : null,
      id,
      imageUrl,
      maxNumberOfPeople: maxNumberOfPeople || 0,
      multipleSlotReservation,
      overbookedSlots: allowNextSlotWhenOverbooked,
      partner: partner.name,
      partnerId: partner.id,
      slug,
      spaces: spaces.map(HappeningsApi.normalizeSpace),
      startDate: HappeningsApi.calculateStartDate(startDate)
    }
  }

  private static normalizeMetadata({
    description,
    language,
    slug,
    title
  }: IHappeningsResponseItemMetadata): IHappeningDescription {
    return {
      description,
      language,
      slug,
      title
    }
  }

  private static normalizeConfigurations({
    id,
    prices,
    upsell
  }: IHappeningResponseConfiguration): IHappeningSlotsConfiguration {
    return {
      id,
      price: prices[0]?.value || 0,
      upSell: !!upsell
    }
  }

  private static normalizeSpace({
    id,
    configurations,
    maxNumberOfPeople,
    isScopeView,
    metadata,
    products,
    rulePrice,
    timeSlot,
    url,
    timeBreak
  }: IHappeningResponseSpace): IHappeningSpace {
    return {
      configurations: configurations.map(HappeningsApi.normalizeConfigurations),
      description: metadata.map(HappeningsApi.normalizeMetadata),
      id,
      maxNumberOfPeople,
      products,
      isScopeView,
      rulePrice,
      timeBreak,
      timeSlot,
      url: url || ''
    }
  }

  private baseUrl: string
  private cancelTokenHappening?: CancelTokenSource
  private cancelTokenHappenings?: CancelTokenSource

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  public getHappenings(partnerId: string | null): Promise<IHappeningsResponse> {
    return new Promise((resolve, reject) => {
      this.cancelTokenHappenings = axios.CancelToken.source()

      axios
        .get(this.getHappeningsUrl(partnerId), {
          cancelToken: this.cancelTokenHappenings.token
        })
        .then(getData)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public normalizeHappenings(response: IHappeningsResponse) {
    return {
      items: response.items,
      totalCount: response.totalCount
    }
  }

  public cancelHappenings() {
    if (this.cancelTokenHappenings) {
      this.cancelTokenHappenings.cancel()
      this.cancelTokenHappenings = undefined
    }
  }

  public getHappening(slug: string): Promise<IHappeningData> {
    return new Promise((resolve, reject) => {
      this.cancelTokenHappening = axios.CancelToken.source()

      axios
        .get(this.getHappeningUrl(slug), {
          cancelToken: this.cancelTokenHappening.token
        })
        .then(getData)
        .then((response: IHappeningResponse) => {
          resolve(HappeningsApi.normalizeHappening(response, slug))
        })
        .catch((error) => reject(catchHttpError(error)))
    })
  }

  public cancelSingleHappening() {
    if (this.cancelTokenHappening) {
      this.cancelTokenHappening.cancel()
      this.cancelTokenHappening = undefined
    }
  }

  private getHappeningsUrl(partnerId: string | null): string {
    if (partnerId) {
      return `${this.baseUrl}happenings?partnerIds[]=${partnerId}`
    }

    return `${this.baseUrl}happenings`
  }

  private getHappeningUrl(slug: string): string {
    return `${this.baseUrl}happenings/${slug}`
  }
}

export default HappeningsApi
